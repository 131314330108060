<template>
  <layout>
    <template #header>
      <app-header>
        <pagination
          :current-page="currentPage"
          :total-pages="totalPages"
          @update="updatePage"
        />
      </app-header>
    </template>
    <div class="content">
      <div class="button-list">
        <inqli-button width="160px" to="/system-admin/users/create"
          >Add user</inqli-button
        >
      </div>
      <user-table :users="users" />
    </div>
  </layout>
</template>
<script>
import Pagination from "../../app/components/Pagination.vue";
import sendRequest from "../../app/utils/send-request-helper";
import UserTable from "./components/UserTable.vue";
import Header from "../../app/components/headers/DefaultHeader";
import DefaultLayout from "../../app/components/layouts/DefaultLayout";
import Button from "../../app/components/buttons/Button";

export default {
  name: "UserList",
  metaInfo: {
    title: "User List",
  },
  components: {
    "user-table": UserTable,
    "app-header": Header,
    layout: DefaultLayout,
    "inqli-button": Button,
    pagination: Pagination,
  },
  data() {
    return {
      users: [],
      currentPage: 1,
      totalPages: 10,
      limit: 10,
    };
  },
  async mounted() {
    await this.getUserList();
  },
  methods: {
    async updatePage(page) {
      this.currentPage = page;
      await this.getUserList();
    },
    async getUserList() {
      try {
        const data = await sendRequest({
          url: `${process.env.VUE_APP_URL}/resources/users?page=${this.currentPage}&limit=${this.limit}`,
          method: "GET",
          isAuth: true,
        });
        this.totalPages = data.total_pages;
        this.users = data.list;
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user list"
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 15px 40px;
  width: 80%;
  margin: 0 auto;
}
.button-list {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
@media (max-width: $breakpoint-tablet-large) {
  .content {
    padding: 10px;
    width: 100%;
  }
}
</style>
