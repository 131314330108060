<template>
  <div>
    <data-table
      :headers="headers"
      :items="users"
      :expanded-menu-items="expandedMenuItems"
    >
      <template v-slot:display_name="{ props }">
        <div>
          <avatar :image="props.item.avatar" size="small"></avatar>
          <span class="user-name">
            {{ props.item.display_name }}
          </span>
        </div>
      </template>
      <template v-slot:created_on="{ props }">
        {{ getFormatTime(props.item.created_on) }}
      </template>

      <template v-slot:expanded-row-content="{ item }">
        <user-detail :user="item" />
      </template>
    </data-table>
  </div>
</template>
<script>
import moment from "moment";
import Avatar from "../../../app/components/avatars/Avatar.vue";
import UserGeneralDetailVue from "../../../users/components/UserGeneralDetail.vue";
import DataTable from "../../../app/components/tables/DataTable.vue";

export default {
  components: {
    avatar: Avatar,
    "user-detail": UserGeneralDetailVue,
    "data-table": DataTable,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headers() {
      return [
        { text: "Name", align: "left", value: "display_name" },
        { text: "Email", align: "left", value: "email" },
        { text: "Created", align: "left", value: "created_on" },
      ];
    },
    expandedMenuItems() {
      return [
        {
          label: "Edit user",
          ariaLabel: "edit-user",
          icon: "mdi-pencil",
          action: this.editUser,
        },
        {
          label: "View profile",
          ariaLabel: "view-profile",
          icon: "mdi-account-details",
          action: this.visitUserProfile,
        },
      ];
    },
  },
  methods: {
    getFormatTime(createdOn) {
      return moment.utc(createdOn).local().format("MMM Do YYYY, h:mm A");
    },
    editUser(user) {
      this.$router.push(`/system-admin/users/edit/${user.id}`);
    },
    visitUserProfile(user) {
      this.$router.push(`/users/${user.id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.user-name {
  font-weight: 600;
  margin-left: 10px;
  color: $inqliNavy;
}
</style>
