var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.users,
          "expanded-menu-items": _vm.expandedMenuItems
        },
        scopedSlots: _vm._u([
          {
            key: "display_name",
            fn: function(ref) {
              var props = ref.props
              return [
                _c(
                  "div",
                  [
                    _c("avatar", {
                      attrs: { image: props.item.avatar, size: "small" }
                    }),
                    _c("span", { staticClass: "user-name" }, [
                      _vm._v(" " + _vm._s(props.item.display_name) + " ")
                    ])
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "created_on",
            fn: function(ref) {
              var props = ref.props
              return [
                _vm._v(
                  " " + _vm._s(_vm.getFormatTime(props.item.created_on)) + " "
                )
              ]
            }
          },
          {
            key: "expanded-row-content",
            fn: function(ref) {
              var item = ref.item
              return [_c("user-detail", { attrs: { user: item } })]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }